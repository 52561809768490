/**
 * Variables globales de la aplicación en local
 */


export const environment = {
  production: false,

  // URL del dominio asociado a la aplicación, para comprobar si está o no suspendido
  domainUrl: 'https://carlos2.xerintel.net/',

  // URL del endpoint de la api de pruebas
  //apiUrl: 'http://192.168.0.160:8000/api/auth/',
  apiUrl: 'https://carlos2.xerintel.net/devxerintel/api/auth/',

  // storageUrl: 'http://192.168.0.160:8000/storage/',
  storageUrl: 'https://carlos2.xerintel.net/devxerintel/storage/',

  //Sender id para las notificaciones push
  senderID: '872145262625',

  //Clave de stripe
  stripePublishableKey: 'pk_test_***************'
};
