import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController, Platform, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public loading: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private toast: ToastController,
    private storage: Storage,
    private navCtrl: NavController) { }


  /**
   * Muestra loading
   * @param message Mensaje del loading (opcional)
   */
  async showLoading(message?: string, duration?: number) {
    this.loading = await this.loadingCtrl.create({
      message: message ? message : null,
      duration: duration ? duration : null
    });
    return this.loading.present();
  }

  /**
   * Quita el loading cargado (arreglado)
   */
  public async dismissLoading() {
    if (this.loading) {
      return this.loading.dismiss().then(() => { return true; })
    }
    return Promise.resolve(true);
  }

  /**
   * Devuelve el sistema operativo del dispositivo
   */
  public getPlatform() {
    return this.platform.is('ios') ? 'ios' : 'android';
  }

  /**
   * Devuelve el nombre del archivo pasado (incluida la extensión)
   * @param path Ruta del archivo
   */
  public getFileName(path: string) {
    return path.split('/').pop();
  }

  /**
   * Devuelve la extensión del archivo pasado
   * @param path Ruta del archivo
   */
  public getFileExtension(path: string) {
    return path.split('.').pop().toLowerCase();
  }

  /**
   * Muestra un alert genérico para notificar algo (un error, éxito, etc)
   * @param title Título del alert
   * @param message Mensaje del alert
   */
  public async showAlert(title: string, message: string, backdropDismiss = true) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['OK'],
      backdropDismiss: backdropDismiss
    });

    alert.present();
  }

  /**
   * Muestra un toast genérico para notificar algo (un error, éxito, etc)
   * @param message Mensaje del toast
   */
  public async showToast(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 5000,
      buttons: ['OK'],
    });
    toast.present();
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //showToast con accion en el boton OK
  public async showToastProducts(message: string) {
    const toast = await this.toast.create({
      message: message,
      duration: 5000,
      buttons: [{
        text: 'OK',
        role: 'back',
        handler: () => {
          this.navCtrl.navigateBack('/tabs/home');
        }
      }],
    });
    toast.present();
  }

  /**
   * Returns an array containing the total errors of an HTTP request.
   * @param httpErrorResponse HTTP response error.
   */
  public getArrayOfHttpErrors(httpErrorResponse: HttpErrorResponse) {
    const errorMessages: string[] = [];
    if (httpErrorResponse.status == 422) {
      const keys = Object.keys(httpErrorResponse.error.errors);
      for (const key of keys) {
        for (const error of httpErrorResponse.error.errors[key]) {
          errorMessages.push(error);
        }
      }
    }
    else {
      if (httpErrorResponse.error.message == "Unauthorized") {
        errorMessages.push("Usuario o contraseña incorrectos");
      }
      else {
        errorMessages.push(httpErrorResponse.error.message);
      }
    }
    return errorMessages;
  }

  /**
   * Handles a HTTP response error showing a helpful report.
   * @param httpErrorResponse HTTP response error.
   */
  public handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse) {
    const errors = this.getArrayOfHttpErrors(httpErrorResponse);
    if (errors.length > 0) {
      this.showToast(errors[0]);
    }
    else {
      this.showToast('Error desconocido');
    }
  }
}