import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'cover-page', pathMatch: 'full' },
  { path: 'cover-page', loadChildren: () => import('./pages/cover-page/cover-page.module').then(m => m.CoverPagePageModule) },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'details', canActivate: [AuthGuard], loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsPageModule) },
  { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'chats', canActivate: [AuthGuard], loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule) },
  { path: 'interior-chat/:id_chat/:nombre_chat/:ultimo_mensaje', canActivate: [AuthGuard], loadChildren: () => import('./pages/interior-chat/interior-chat.module').then(m => m.InteriorChatPageModule) },
  { path: 'tabs', canActivate: [AuthGuard], loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'profile-edit', canActivate: [AuthGuard], loadChildren: () => import('./pages/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule) },
  { path: 'notifications', canActivate: [AuthGuard], loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule) },
  { path: 'cart', canActivate: [AuthGuard], loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule) },
  { path: 'product/:id', canActivate: [AuthGuard], loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule)},
  { path: 'product-list/:id', canActivate: [AuthGuard], loadChildren: () => import('./pages/product-list/product-list.module').then(m => m.ProductListPageModule) },
  { path: 'delivery', canActivate: [AuthGuard], loadChildren: () => import('./pages/delivery/delivery.module').then(m => m.DeliveryPageModule) },
  { path: 'policy', loadChildren: './pages/policy/policy.module#PolicyPageModule' },
  { path: 'order-detail', loadChildren: './pages/order-detail/order-detail.module#OrderDetailPageModule' },
  { path: 'my-orders', loadChildren: './pages/my-orders/my-orders.module#MyOrdersPageModule' },
  { path: 'order-interior/:id', loadChildren: './pages/order-interior/order-interior.module#OrderInteriorPageModule' },
  { path: 'my-documents', loadChildren: './pages/my-documents/my-documents.module#MyDocumentsPageModule' },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
